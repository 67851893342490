import client from '@/api/AxiosInstance.js'
export default {
  getApi() {
    return client.get('')
  },

  register(params) {
    return client.post('/auth/register', {
      name: params.name,
      phone_number: params.phone_number,
      dob: params.dob,
      province_of_work: params.province_of_work,
      province_of_resident: params.province_of_resident,
      income_type: params.income_type,
      salary: params.salary,
      email: params.email,
    })
  },

  getOTP(params) {
    return client.post('/auth/send-otp', {
      phone_number: params.phone_number,
      forget_password: params.forget_password,
    })
  },

  login(params) {
    return client.post('/auth/login', {
      phone_number: params.phone_number,
      type: params.type, // pincode | otpcode
      code: params.code,
      forget_password: params.forget_password,
    })
  },

  setPassword(params) {
    return client.post('/auth/set-password', {
      phone_number: params.phone_number,
      password: params.password,
      forget_password: params.forget_password,
      auth_token: params.auth_token,
    })
  },

  getUserProfile() {
    return client.get('/profile')
  },

  updateProfile(params) {
    return client.post('/profile', {
      name: params.name,
      phone_number: params.phone_number,
      dob: params.dob,
      province_of_work: params.province_of_work,
      province_of_resident: params.province_of_resident,
      income_type: params.income_type,
      salary: params.salary,
      email: params.email,
    })
  },

  getOTPToChangePhone(params) {
    return client.post('/change-number/send-otp', {
      phone_number: params.phone_number,
    })
  },

  changePhoneNumber(params) {
    return client.post('/change-number', {
      phone_number: params.phone_number,
      code: params.code,
    })
  },

  destoryCache(params) {
    return client.post('/auth/destory-cache', {
      phone_number: params.phone_number,
    })
  },

  checkOTP(params) {
    return client.post('/auth/check-otp', {
      phone_number: params.phone_number,
      code: params.code,
      forget_password: params.forget_password,
    })
  },

  setLanguage() {
    return client.post('/auth/set-lang')
  },
}
