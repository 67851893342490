<template>
  <b-modal
    :visible="isBusy"
    centered
    scrollable
    size="sm"
    hide-footer
    hide-header
    content-class="bg-transparent border-0"
    no-close-on-backdrop
  >
    <div class="d-flex justify-content-center">
      <div class="spinner-grow text-primary" role="status"></div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    isBusy: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style></style>
